html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:root {
  --amplify-background-color: var(–amplify-white);
  --amplify-primary-color: #222222;
  --amplify-primary-contrast: var(–amplify-white);
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
  --amplify-secondary-color: #152939;
  --amplify-secondary-contrast: var(–amplify-white);
  --amplify-secondary-tint: #31465f;
  --amplify-secondary-shade: #1f2a37;
  --amplify-tertiary-color: #5d8aff;
  --amplify-tertiary-contrast: var(–amplify-white);
  --amplify-tertiary-tint: #7da1ff;
  --amplify-tertiary-shade: #537be5;
  --amplify-grey: #828282;
  --amplify-light-grey: #c4c4c4;
  --amplify-white: #ffffff;
  --amplify-red: #dd3f5b;
}

.MuiSnackbar-anchorOriginBottomCenter {
  right: unset !important;
  left: 8px !important;
  width: calc(100vw - 19.5rem) !important;
  margin-left: 17rem !important;
  transform: unset !important;
}

@font-face {
  font-family: 'Virgil';
  src: url('fonts/Virgil.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Cascadia';
  src: url('fonts/Cascadia.woff2');
  font-display: swap;
}
